<template>
	<div class="row">
		<div class="container-fluid">
			<div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto mb-5" v-if="loaded">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">{{ user.username }}</h4>
						<h5 class="card-subtitle mb-2 text-muted">{{ posts.reduce((upvotes, post) => upvotes + post.upvotes, 0) }} post upvotes</h5>
						<p class="card-text">{{ joinedAgoMessage }}</p>
					</div>
				</div>
			</div>
			<div class="container-fluid text-center">
				<p class="fs-2">Posts from user</p>
			</div>
			<feed :posts="posts"></feed>
		</div>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapFeedState } = createNamespacedHelpers("feed");

export default {
	data() {
		return {
			loaded: false,
			user: {}
		};
	},
	async created() {
		await this.resolveProfile();
		this.loaded = true;
	},
	methods: {
		async resolveProfile() {
			try {
				this.user = await this.$store.dispatch("users/getUserById", this.$route.params.id);
			} catch(err) {
				this.$router.replace({ name: "404", params: { catchAll: " " } });
			}
		}
	},
	computed: {
		...mapFeedState({
			posts(state) {
				return state.feed.filter(post => post.author === this.user.id);
			}
		}),
		joinedAgoMessage() {
			const date = new Date(this.user.createdAt);

			return `Joined on ${date.getMonth() + 1}/${date.getDay() + 1}/${date.getFullYear()}`;
		}
	}
};
</script>
